import { render, staticRenderFns } from "./SetupSaml.vue?vue&type=template&id=596dfd60&scoped=true&"
import script from "./SetupSaml.vue?vue&type=script&lang=js&"
export * from "./SetupSaml.vue?vue&type=script&lang=js&"
import style0 from "./SetupSaml.vue?vue&type=style&index=0&id=596dfd60&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "596dfd60",
  null
  
)

/* custom blocks */
import block0 from "./SetupSaml.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VContainer,VFileInput,VForm,VIcon,VSimpleTable,VTextField})
