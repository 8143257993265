<i18n>
{
  "en": {
    "headline": "LINE WORKS Group name",
    "group-name-explainer": "Enter your LINE WORKS Group name. This is the part after \"@\" in your LINE WORKS user ID.",
    "group-name": "Group name",
    "continue": "Continue",
    "developer-console-explainer": "To begin integration of LiveCall Bot into your LINE WORKS workspace, you need to enable Single Sign On with SAML in your LINE WORKS Developer Console. Click here to open your Developer Console:",
    "saml-page-explainer": "On the {0} → {1} page, click the {2} button and configure the new app as shown below.",
    "add-button": "ADD",
    "idp-info-headline": "LINE WORKS Identity Provider Info",
    "idp-explainer": "Make sure this information is the same as shown in your LINE WORKS Developer Console, or correct it if necessary. Download the certificate from the LINE WORKS Developer Console and select the downloaded file below in the \"Certificate\" field.",
    "application-info-headline": "Application Info",
    "application-info-explainer": "In your LINE WORKS Developer Console, enter a name and description as shown below.",
    "copy-explainer": "Note: Click the {0} button to easily copy the information.",
    "sp-info-headline": "Service Provider Info",
    "sp-info-explainer": "In your LINE WORKS Developer Console, enter the information exactly as shown below.",
    "activation-headline": "Adjust activation status and application scope",
    "activation-explainer": "After configuring the above settings, click {0} → {1} to finish the configuration. Afterwards, click the newly configured app's {2} button, set the \"Activation status and application scope\" to {3}, then click {4}.",
    "next-button": "Next",
    "change-button": "Adjust",
    "active-label": "Activate",
    "save-button": "Save",
    "confirmation-explainer": "When everything is configured, click below to sign into LiveCall.",
    "log-in-using-lineworks": "Log in using LINE WORKS",
    "is-required": "Please enter a value",
    "invalid-url": "This is not a valid URL"
  },
  "ja": {
    "headline": "ワークスグループ名の入力",
    "group-name-explainer": "LINE WORKSユーザーIDの「＠」以降部分の、ワークスグループ名を入力してください。",
    "group-name": "ワークスグループ名",
    "continue": "進む",
    "developer-console-explainer": "LiveCall for LINE WORKSの利用を開始するには、LINE WORKS Developer ConsoleでSAMLを使用したシングルサインオン（SSO）を有効にする必要があります。LINE WORKS Developer Consoleを開き、下記手順にそって、設定を行ってください。",
    "saml-page-explainer": "{0} → {1} のページ内で、［SAML Apps］の {2} をクリックし、下記項目の設定を行ってください。",
    "add-button": "追加",
    "idp-info-headline": "LINE WORKS Identity Provider情報",
    "idp-explainer": "LINE WORKS Developer Console側に表示されている［SSO URL］、［Response Issuer］が下記と同じであることを確認し、［LINE WORKS Identity Provider情報］→［Certificate］をダウンロードしてください。その後、ダウンロードしたファイルを、下記［Certificate］にアップロードしてください。",
    "application-info-headline": "Application情報",
    "application-info-explainer": "LINE WORKS Developer Console側の［Application情報］に、下記、［Application Name］と［Description］を入力してください。",
    "copy-explainer": "※ {0} をクリックすると、簡単にコピー出来ます。",
    "sp-info-headline": "Service Provider情報",
    "sp-info-explainer": "LINE WORKS Developer Console側の［Service Provider情報］に、下記、［ACS URL］、［SP Issuer (Entity Id)］を入力してください。",
    "activation-headline": "使用状況と適応範囲の有効化",
    "activation-explainer": "上記設定を完了後、LINE WORKS Developer Console側で {0} → {1} とすすみ、SAML Appsの登録を完了した後、{2} をクリックし、［使用状況と適応範囲］を {3} にして {4} してください。",
    "next-button": "次へ",
    "change-button": "変更",
    "active-label": "有効",
    "save-button": "保存",
    "confirmation-explainer": "上記すべて完了後、以下をクリックして、LiveCallにサインインしてください。",
    "log-in-using-lineworks": "LINE WORKSを使ってログイン",
    "is-required": "必ず入力してください",
    "invalid-url": "無効なＵＲＬです"
  }
}
</i18n>
<template>
  <v-container class="px-0">
    <h3 class="mt-5">{{ $t('headline') }}</h3>

    <p>{{ $t('group-name-explainer') }}</p>

    <v-form @submit.prevent="processGroupName">
      <v-text-field outlined
                    :label="$t('group-name')"
                    placeholder="works-123456"
                    prefix="@"
                    v-model="groupId"
                    :disabled="showInstructions">
      </v-text-field>

      <v-btn v-if="!showInstructions" type="submit" color="primary" :disabled="!groupId || working" :loading="working">
        {{ $t('continue') }}
      </v-btn>
    </v-form>

    <v-form v-if="showInstructions"
            :action="setupUrl"
            method="POST"
            enctype="multipart/form-data"
            @submit.passive="working = true"
            v-model="valid"
            class="mt-7">
      <p>{{ $t('developer-console-explainer') }}</p>

      <p>
        <v-btn color="primary" target="_blank" href="https://developers.worksmobile.com/jp/console/idp/saml/view">
          LINE WORKS Developer Console
          <v-icon right>mdi-open-in-new</v-icon>
        </v-btn>
      </p>

      <h3 class="mt-7">{{ $t('idp-info-headline') }}</h3>

      <p>{{ $t('idp-explainer') }}</p>

      <v-simple-table class="my-7">
        <tbody>
        <tr>
          <th>SSO URL</th>
          <td>
            <v-text-field outlined
                          dense
                          hide-details="auto"
                          name="idp_url"
                          :value="ssoUrl"
                          :rules="[requiredRule, validUrlRule]"
                          placeholder="SSO URL">
            </v-text-field>
          </td>
        </tr>
        <tr>
          <th>Response Issuer</th>
          <td>
            <v-text-field outlined
                          dense
                          hide-details="auto"
                          name="entity_id_url"
                          :value="responseIssuerUrl"
                          :rules="[requiredRule, validUrlRule]"
                          placeholder="Response Issuer">
            </v-text-field>
          </td>
        </tr>
        <tr>
          <th>Certificate</th>
          <td>
            <v-file-input outlined
                          dense
                          hide-details="auto"
                          name="idp_certificate"
                          :rules="[requiredRule]"
                          placeholder="Certificate">
            </v-file-input>
          </td>
        </tr>
        </tbody>
      </v-simple-table>

      <i18n path="saml-page-explainer" tag="p">
        <strong>Apps</strong>
        <strong>SAML Apps</strong>
        <sample>{{ $t('add-button') }}</sample>
      </i18n>

      <h3 class="mt-7">{{ $t('application-info-headline') }}</h3>

      <p>{{ $t('application-info-explainer') }}</p>

      <i18n path="copy-explainer" tag="p" class="caption">
        <v-icon small>mdi-content-copy</v-icon>
      </i18n>

      <v-simple-table class="my-7">
        <tbody>
        <tr>
          <th>Application Name</th>
          <td>
            <v-text-field outlined dense hide-details value="LiveCall" readonly>
              <template v-slot:prepend>
                <copy text="LiveCall"></copy>
              </template>
            </v-text-field>
          </td>
        </tr>
        <tr>
          <th>Description</th>
          <td>
            <v-text-field outlined dense hide-details value="LiveCall LINE WORKS bot" readonly>
              <template v-slot:prepend>
                <copy text="LiveCall LINE WORKS bot"></copy>
              </template>
            </v-text-field>
          </td>
        </tr>
        </tbody>
      </v-simple-table>

      <h3 class="mt-7">{{ $t('sp-info-headline') }}</h3>

      <p>{{ $t('sp-info-explainer') }}</p>

      <v-simple-table class="my-7">
        <tbody>
        <tr>
          <th>ACS URL</th>
          <td>
            <v-text-field outlined dense hide-details :value="acsUrl" readonly>
              <template v-slot:prepend>
                <copy :text="acsUrl"></copy>
              </template>
            </v-text-field>
          </td>
        </tr>
        <tr>
          <th>SP Issuer (Entity Id)</th>
          <td>
            <v-text-field outlined dense hide-details :value="entityIdUrl" readonly>
              <template v-slot:prepend>
                <copy :text="entityIdUrl"></copy>
              </template>
            </v-text-field>
          </td>
        </tr>
        <tr>
          <th>Name ID</th>
          <td>EMAIL</td>
        </tr>
        <tr>
          <th>Name ID Format</th>
          <td>UNSPECIFIED</td>
        </tr>
        </tbody>
      </v-simple-table>

      <h3 class="mt-7">{{ $t('activation-headline') }}</h3>

      <i18n path="activation-explainer" tag="p">
        <sample>{{ $t('next-button') }}</sample>
        <sample>OK</sample>
        <sample>{{ $t('change-button') }}</sample>
        <label class="ml-1 sample"><input type="radio" checked readonly> {{ $t('active-label') }}</label>
        <sample>{{ $t('save-button') }}</sample>
      </i18n>

      <p class="mt-7">{{ $t('confirmation-explainer') }}</p>

      <input type="hidden" name="group" :value="groupId">
      <input type="hidden" name="next" :value="nextUrl">
      <input type="hidden" name="fail" :value="failUrl">

      <v-btn color="primary"
             type="submit"
             :disabled="!valid || working"
             :loading="working">
        {{ $t('log-in-using-lineworks') }}
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
const localStorageKey = 'lineworksGroupId';

export default {
  name: 'SetupSaml',
  components: {
    Copy: () => import('./CopyButton'),
    Sample: () => import('./SampleButton')
  },
  props: ['nextUrl', 'failUrl'],
  data: () => ({
    working: false,
    groupId: window.localStorage.getItem(localStorageKey),
    ssoUrl: null,
    responseIssuerUrl: null,
    showInstructions: false,
    valid: false
  }),
  computed: {
    acsUrl() {
      return `${this.$api.base}/saml/${this.groupId}`;
    },
    entityIdUrl() {
      return `${this.acsUrl}/metadata`;
    },
    setupUrl() {
      return `${this.$api.base}/admin/signup`;
    }
  },
  methods: {
    requiredRule(v) {
      return !!v || this.$t('is-required');
    },
    validUrlRule(v) {
      return /^https?:\/\/.+\..+$/.test(v) || this.$t('invalid-url')
    },
    async processGroupName() {
      this.working = true;
      /** @type {Response} */
      const response = await this.$api.fetch(`/admin/signup?group=${this.groupId}`);

      if (!response.ok) {
        this.working = false;
        throw new Error(`Unexpected response from server: ${response}`);
      }

      const result = await response.json();
      const next = result.next && result.next.toLowerCase();

      if (['saml', 'api'].includes(next)) {
        this.showInstructions = true;
        this.working = false;
        this.ssoUrl = `https://auth.worksmobile.com/saml2/idp/${this.groupId}`;
        this.responseIssuerUrl = `https://auth.worksmobile.com/saml2/${this.groupId}`;
        window.localStorage.setItem(localStorageKey, this.groupId);
        return;
      }

      console.log('SAML configuration already complete, redirecting to next step...')

      let nextUrl;

      if (!next) {
        const url = new URL(window.location);
        url.pathname = '/';
        nextUrl = url.toString();
      } else {
        nextUrl = this.nextUrl;
      }

      window.location = `${this.acsUrl}?next=${encodeURIComponent(nextUrl)}`;
    }
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.v-data-table >>> table tbody tr th {
  max-width: 80px;
}

label.sample {
  font-weight: bold;
}
</style>